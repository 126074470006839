import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import React from "react";

const secondClass = [
  { type: "", name: "不限" },
  { type: "vehicle", name: "车辆" },
  { type: "traffic", name: "其他交通运输工具" },
  { type: "residence", name: "住宅用房" },
  { type: "commercial", name: "商业用房" },
  { type: "industrial", name: "工业用房" },
  { type: "otherhouse", name: "其他用房" },
  { type: "buildland", name: "建设用地" },
  { type: "otherland", name: "其他用地" },
  { type: "contractland", name: "土地承包经营权" },
  { type: "homestead", name: "宅基地使用权" },
  { type: "sea", name: "海域使用权" },
  { type: "ship", name: "船舶" },
  { type: "fishboat", name: "渔船" },
  { type: "aircraft", name: "航空器" },
  { type: "property", name: "一般动产" },
  { type: "otherassets", name: "其他财产" },
  { type: "stock", name: "股票" },
  { type: "fund", name: "基金" },
  { type: "bond", name: "债券" },
  { type: "equity", name: "股权" },
  { type: "debt", name: "债权" },
  { type: "equipment", name: "机器设备" },
  { type: "material", name: "产品原材料" },
  { type: "knowledge", name: "知识产权" },
  { type: "artwork", name: "古玩字画" },
  { type: "forest", name: "森林、林木所有权" },
  { type: "collective", name: "集体土地所有权" },
  { type: "gems", name: "珠宝玉石首饰" },
  { type: "mine", name: "探矿/采矿权" },
];

//拍卖状态
const auctionStatus = [
  { label: "即将开始", value: "todo" },
  { label: "正在进行", value: "doing" },
];
const failAuctionStatus = [
  { label: "待确认", value: "1" },
  { label: "可处置", value: "2" },
  { label: "不可处置", value: "3" },
];
//拍卖类型
const auctionType = [
  { label: "司法拍卖", value: "1" },
  { label: "资产拍卖", value: "2" },
];

//资产类型
const zy_type = [
  { label: "司法拍卖", value: 1 },
  { label: "资产拍卖", value: 2 },
  { label: "资产推介", value: 3 },
];

//热门搜索
const popularSearch = [
  { label: "住宅用房", value: "住宅用房" },
  { label: "商业用房", value: "商业用房" },
  { label: "工业用房", value: "工业用房" },
];
//标的排序
const objectSort = [
  { label: "默认排序", value: "" },
  { label: "最新发布", value: "1" },
  { label: "当前价格由高到低", value: "2" },
  { label: "当前价格由低到高", value: "3" },
];

// 流拍寻宝标的排序
const failObjectSort = [
  { label: "默认排序", value: "" },
  { label: "当前价格由高到低", value: "1" },
  { label: "当前价格由低到高", value: "2" },
];

//标的物价格区间
const objectPriceSelect = [
  { label: "100万以下", value: [0, 1000000] },
  { label: "100-150万", value: [1000000, 1500000] },
  { label: "150-200万", value: [1500000, 2000000] },
  { label: "200-300万", value: [2000000, 3000000] },
  { label: "300-500万", value: [3000000, 5000000] },
  { label: "500万以上", value: [5000000, ""] },
];

//银行信息
const bankSelect = [
  "中国工商银行",
  "中国农业银行",
  "中国银行",
  "中国建设银行",
  "中国邮政储蓄银行",
  "交通银行",
  "招商银行",
  "平安银行",
  "浦发银行",
  "兴业银行",
  "广发银行",
  "中国民生银行",
  "中信银行",
  "中国光大银行",
];

const packageType = [
  {
    name: "基础套餐",
    content: "相关网站4篇、知名网站3篇、公众号首条",
    price: 3000,
  },
  {
    name: "标准套餐",
    content: "搜索结果列表置顶、相关网站4篇、知名网站3篇、公众号首条",
    price: 6000,
  },
  {
    name: "尊享套餐",
    content: "搜索结果列表置顶、首页曝光、相关网站4篇、知名网站3篇、公众号首条",
    price: 15000,
  },
];

const sourceSelect = [
  {
    label: "淘宝",
    value: "1",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/c82147c643202b5a3e3c34acc4121d83.svg%2Bxml",
  },
  {
    label: "京东",
    value: "2",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/3451a4b25ac81a35ac7909bfa292e4fe.svg%2Bxml",
  },
  {
    label: "人民法院诉讼资产网",
    value: "3",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/766a5d403590130f0833d90b004d7661.svg%2Bxml",
  },
  {
    label: "公拍网",
    value: "4",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/72e24374363914109a54a7975952af0d.svg%2Bxml",
  },
  {
    label: "中拍",
    value: "5",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/a6e1953190ae97a487c78d43e67b5416.svg%2Bxml",
  },
  {
    label: "北交互联",
    value: "6",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/7e490c5eb3bce91e074b1088eb8a86aa.svg%2Bxml",
  },
  {
    label: "工行融e购",
    value: "7",
    img_path:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/7f2e2ca953c9ea58cbd032582b302388.svg%2Bxml",
  },
];

const orderStatus = [
  { label: "全部", value: "", icon: "" },
  {
    label: "待支付",
    value: 0,
    icon: <InfoCircleOutlined style={{ color: "#1890f1", fontSize: "20px" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/537bbc1da2ae6b12e050f4fbfc186f5a.png",
    fontColor: "#1890f1",
  },
  {
    label: "已支付",
    value: 1,
    icon: (
      <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "20px" }} />
    ),
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/87097896296f34457103ebfa7c93ea0b.png",
    fontColor: "#52c41a",
  },
  {
    label: "退款中",
    value: 3,
    icon: (
      <ClockCircleOutlined style={{ color: "#CC453D", fontSize: "20px" }} />
    ),
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/db676a61402b60957774544cca9b9946.png",
    fontColor: "#CC453D",
  },
  {
    label: "已退款",
    value: 4,
    icon: (
      <CheckCircleOutlined style={{ color: "#CC453D", fontSize: "20px" }} />
    ),
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/87097896296f34457103ebfa7c93ea0b.png",
    fontColor: "#CC453D",
  },
  {
    label: "已取消",
    value: 2,
    icon: (
      <CloseCircleOutlined style={{ color: "#999999", fontSize: "20px" }} />
    ),
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/4ffd3334474650970bc22c2fc8986765.png",
    fontColor: "#999999",
  },
];

const payType = [
  { label: "支付宝", value: 1 },
  { label: "微信", value: 2 },
  { label: "余额", value: 3 },
];

const tradeStatus = [
  { label: "未支付", value: 0 },
  { label: "已支付", value: 1 },
  { label: "已取消", value: 2 },
  { label: "退款中", value: 3 },
  { label: "已退款", value: 4 },
  { label: "平台审核", value: 5 },
];


//上下架审核状态
const is_start_select = [
  {
    label: "下架",
    value: 1,
    icon: <CloseCircleOutlined style={{ color: "#999999" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/48386caeba46a3e7fbfac1754c8b7e48.png",
  },
  {
    // label: "审核中",
    label: '待上架',
    value: 2,
    icon: <ClockCircleOutlined style={{ color: "#CC453D" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/d8fbdb179b7dea7a3b2af330fdf26bf4.png",
  },
  {
    label: "审核拒绝",
    value: 3,
    icon: <ClockCircleOutlined />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/4ffd3334474650970bc22c2fc8986765.png",
  },
  {
    label: "上架",
    value: 4,
    icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8850cfa638761a94629a035cfaa2dd19.png",
  },
];

//资产推介上下状态
const is_start_select_zctj = [
  {
    label: "下架",
    value: 1,
    icon: <CloseCircleOutlined style={{ color: "#999999" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/48386caeba46a3e7fbfac1754c8b7e48.png",
  },
  {
    // label: "审核中",
    label: '未上架',
    value: 2,
    icon: <ClockCircleOutlined style={{ color: "#CC453D" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/d8fbdb179b7dea7a3b2af330fdf26bf4.png",
  },
  {
    label: "上架",
    value: 4,
    icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8850cfa638761a94629a035cfaa2dd19.png",
  },
];

//认领审核状态
const claimStatus = [
  {
    label: "待审核",
    value: 1,
    icon: <ClockCircleOutlined style={{ color: "#CC453D" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/537bbc1da2ae6b12e050f4fbfc186f5a.png",
  },
  {
    label: "审核拒绝",
    value: 2,
    icon: <ClockCircleOutlined />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/4ffd3334474650970bc22c2fc8986765.png",
  },
  {
    label: "已认领",
    value: 3,
    icon: <ClockCircleOutlined style={{ color: "#52c41a" }} />,
    statusIcon:
      "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/34f8a1d5318b030808937c66c88a78a4.png",
  },
];
//订单取消原因
const cancelReasonSelect = [
  { checked: false, value: "不想要了" },
  { checked: false, value: "拍错了" },
  { checked: false, value: "地区业务不支持" },
  { checked: false, value: "其他" },
];

const withdrawStatus = [
  { label: "待审核", value: 1 },
  { label: "已提现", value: 2 },
  { label: "提现拒绝", value: 3 },
];

// 标的来源平台
const ptSelect = [
  { label: "产权交易所", value: 1 },
  { label: "浙商资产", value: 2 },
  { label: "自由受托资产", value: 3 },
  { label: "中房网", value: 4 },
  { label: "银行", value: 5 },
  { label: "其他", value: 6 },
];

const codeSelect = [
  "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/d509328af7d3cc3ab00be566e8344f4a.png",
  "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/bfd340ee1ebdb766f1fe5796e1481cd7.png",
];

const wxCodeImg = "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/upload/1731378077895_download.jpg"

export {
  auctionStatus,
  auctionType,
  popularSearch,
  objectSort,
  objectPriceSelect,
  secondClass,
  bankSelect,
  packageType,
  zy_type,
  orderStatus,
  cancelReasonSelect,
  tradeStatus,
  payType,
  is_start_select,
  claimStatus,
  withdrawStatus,
  sourceSelect,
  codeSelect,
  failAuctionStatus,
  failObjectSort,
  ptSelect,
  is_start_select_zctj,
  wxCodeImg

};
