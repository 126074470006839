import { message } from "antd";
import moment from "moment";
const apiPath =
  process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENV === "stage"
    ? "https://sasapi.laipaifafu.com/test_assets_pc/public/index.php"
    : "https://assets-pc.m-satrix.com";
// https://sasapi.laipaifafu.com/test_assets_pc/public/index.php
// "https://sasapi.laipaifafu.com/test_assets"
// "https://sasapi.laipaifafu.com/test_assets_pc/public/index.php"
// https://sasapi.laipaifafu.com/test_admin/index.php/Api

// const apiPath = "https://assets-pc.m-satrix.com";

const ajax = ({
  host = apiPath,
  path,
  method = "POST",
  data = {},
  isNeedToken = true,
  ableEmpty = false,
}) => {
  const _method = method.toUpperCase();
  let _url = host + path;
  let body = null;
  if (_method === "GET") {
    Object.keys(data).map((item, index) => {
      if (data[item]) {
        _url +=
          index === 0 ? `?${item}=${data[item]}` : `&${item}=${data[item]}`;
      }
      return item;
    });
  } else {
    if (ableEmpty) {
      Object.keys(data).map((item) => {
        if (typeof data[item] === "undefined" || data[item] === null) {
          delete data[item];
        }
        return item;
      });
    } else {
      Object.keys(data).map((item) => {
        if (
          typeof data[item] === "undefined" ||
          data[item] === null ||
          data[item] === ""
        ) {
          delete data[item];
        }
        return item;
      });
    }
    const formData = new FormData();
    Object.keys(data).map((item) => {
      if (moment.isMoment(data[item])) {
        data[item] = moment(new Date(data[item])).format("YYYY/MM/DD HH:00:00");
      }
      formData.append(item, data[item]);
      return item;
    });
    body = formData;
  }
  let options = {
    method: _method,
    headers: {
      Client: "pc",
      Token: localStorage.token,
    },
    body,
  };
  if (!isNeedToken) {
    options = {
      method: _method,
      headers: {
        Client: "pc",
      },
      body,
    };
  }
  return new Promise((resolve, reject) => {
    fetch(_url, options)
      .then((response) => response.json())
      .then((res) => {
        if (res && !res.status) {
          resolve(res);
        } else if (res && res.status) {
          const { status } = res;
          if (status === 200) {
            resolve(res);
          } else if (status === 401 || status === 10001) {
            localStorage.clear();
            message.error("当前登录已失效，请重新登陆");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            reject(res.message || res.msg);
          } else {
            message.error(res.message || res.msg);
            reject(res.message || res.msg);
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default ajax;
