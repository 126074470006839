import React, { useEffect, useState } from "react";
import Header from "../../component/Head";
import Footer from "../../component/Footer";
import HotObject from "../../component/HotObject";
import { useLocation } from "react-router-dom";
import apis from "../../static/apis";
import { address } from "../../static/address";
import "./style.scss";
import queryToData from "../../func/queryToData";
import { Input, Empty } from "antd";
import { connect } from "react-redux";
import {
  SwapOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  auctionStatus,
  auctionType,
  popularSearch,
  objectSort,
  failObjectSort,
  objectPriceSelect,
  sourceSelect,
  codeSelect,
  failAuctionStatus,
  wxCodeImg,
} from "../../static/option";

import store from "../../store";
import { getSecondClass } from "../../store/actionCreators";
import ajax from "../../func/ajax";
import transPrice from "../../func/transPrice";
import { searchHistory } from "../../func/fn";

function Search(props) {
  let { second_class, history } = props;
  const sortList =
    queryToData().currentIndex === "2" ? failObjectSort : objectSort;
  const { pathname } = useLocation();
  const [searchList, setSearchList] = useState(
    localStorage.searchHistory ? JSON.parse(localStorage.searchHistory) : []
  );
  const [areaChildIndex, setAreaChildIndex] = useState("");
  const [second_class_value, setSecond_class_value] = useState("");
  const [price, setPrice] = useState([]);
  const [object_status, setObject_status] = useState("");
  const [zc_type, setZc_type] = useState(
    !queryToData().currentIndex
      ? ""
      : queryToData().currentIndex === "1"
        ? 1
        : queryToData().currentIndex > 2
          ? queryToData().currentIndex - 1
          : ""
  );
  const [order_type, setOrder_type] = useState("");
  const [objectList, setObjectList] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState(decodeURI(queryToData().name || ""));
  const [marginLeft, setMargetLeft] = useState("");
  const [childML, setChildML] = useState("");
  const [provinceCode, setProvinceCode] = useState("100000");
  const [cityCode, setCityCode] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [showProvinceChildren, setShowProvinceChildren] = useState(-1);
  const [showCityChildren, setShowCityChildren] = useState(-1);
  const [jumpPage, setJumpPage] = useState(null);
  const [disposal_status, setDisposal_status] = useState("");
  const size = 10;

  useEffect(() => {
    store.dispatch(getSecondClass());
  }, []);
  useEffect(() => {
    if (queryToData().name) {
      setName(decodeURI(queryToData().name));
      setSearchList(searchHistory(decodeURI(queryToData().name)));
    }
  }, [queryToData().name]);
  useEffect(() => {
    getObjectList();
  }, [
    queryToData().currentIndex,
    queryToData().name,
    provinceCode,
    cityCode,
    areaCode,
    second_class_value,
    object_status,
    order_type,
    // zc_type,
    areaChildIndex,
    disposal_status,
    // price,
  ]);

  useEffect(() => {
    setName("");
    setProvinceCode("100000");
    setCityCode(null);
    setAreaCode(null);
    setAreaChildIndex("");
    setShowProvinceChildren(-1);
    setShowCityChildren(-1);
    setPage(1);
    setPrice([]);
    setSecond_class_value("");
    setZc_type("");
    setObject_status("");
    setOrder_type("");
    setDisposal_status("");
  }, [queryToData().currentIndex]);

  const Reset = () => {
    setProvinceCode("100000");
    setCityCode(null);
    setAreaCode(null);
    setAreaChildIndex("");
    setShowProvinceChildren(-1);
    setShowCityChildren(-1);
    setPage(1);
    setPrice([]);
    setSecond_class_value("");
    setZc_type("");
    setObject_status("");
    setDisposal_status("");
  };

  const getObjectList = (newPagination, price, zcType) => {
    const _page = newPagination || 1;
    const _price = price || [];
    const _zc_type = !queryToData().currentIndex
      ? ""
      : queryToData().currentIndex === "1"
        ? 1
        : queryToData().currentIndex > 2
          ? queryToData().currentIndex - 1
          : zcType
            ? zcType
            : "";
    setZc_type(_zc_type);
    setPage(_page);
    setPrice(_price);
    const ajaxUrl =
      queryToData().currentIndex === "2"
        ? apis.ObjectFailureList
        : apis.objectList;
    ajax({
      path: ajaxUrl,
      data: {
        load_province: provinceCode === "100000" ? "" : provinceCode,
        load_city: cityCode,
        load_district: areaCode,
        second_class: second_class_value,
        price_min: price ? price[0] : "",
        price_max: price ? price[1] : "",
        object_status: queryToData().currentIndex === "4" ? "" : object_status,
        disposal_status:
          queryToData().currentIndex === "2" ? disposal_status : "",
        order_type,
        zc_type: _zc_type,
        page: _page,
        size,
        name: queryToData().name ? decodeURI(queryToData().name) : "",
      },
      isNeedToken: true,
      ableEmpty: true,
    }).then((res) => {
      if (res.list.length > 0) {
        res.list.map((item) => {
          sourceSelect.map((sourceItem) => {
            if (sourceItem.label === item.source) {
              item.sourceIcon = sourceItem.img_path;
            }
          });
          return item;
        });
      }
      setObjectList(res.list);
      // setCount(res.count || 0);
    });
  };

  const searchTitle = (value = name) => {
    const urlData = queryToData();
    urlData.name = value;
    let _params = "";
    Object.keys(urlData).forEach((item) => {
      if (item && item !== "") {
        _params += `&${item}=${urlData[item]}`;
      }
    });
    _params = _params.substr(1);
    // console.log("/search?" + _params);
    history.push("/search?" + _params);
  };

  return (
    <div className={"search-container"}>
      <Header currentIndex={queryToData().currentIndex} />
      <div className={"search-content"}>
        <div className={"banner"}>
          <div className={"banner-content container-1200"}>
            <div className={"line"}></div>
            <div className={"des"}>
              <div>矩阵资产，了解你身边的资产</div>
            </div>
            <div className={"search"}>
              <div className={"inputItem"}>
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onPressEnter={() => searchTitle()}
                  placeholder={"请输入您要搜索的标的物名称"}
                />
              </div>
              <div className={"search_btn"} onClick={() => searchTitle()}>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/ece3523e195837c7eccf4a4aa2313d3d.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className={"history-search"}>
            {searchList.length > 0 && (
              <div className={"history-search-item container-1200"}>
                <div>历史搜索 :</div>
                <ul>
                  {searchList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          searchTitle(item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className={"history-search-item container-1200"}>
              <div>热门搜索 :</div>
              <ul>
                {popularSearch.map((item, index) => {
                  return (
                    <li
                      key={item.value}
                      onClick={() => {
                        searchTitle(item.value);
                      }}
                    >
                      {item.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={"condition-part"}>
          <div className={"condition-part-content container-1200"}>
            <ul className={"condition-part-item"}>
              <li className={["condition-part-types area"].join(" ")}>
                <div className={"condition-part-types-name"}>
                  区&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;域:
                </div>
                <ul className={"condition-part-types-item"}>
                  {address.map((item, index) => {
                    return (
                      <li
                        key={item.value}
                        onClick={(e) => {
                          setMargetLeft(-e.currentTarget.offsetLeft);
                        }}
                        onMouseLeave={(e) => {
                          setShowProvinceChildren(-1);
                          setShowCityChildren(-1);
                        }}
                        className={"provinceCode"}
                      >
                        <span
                          onClick={() => {
                            //省点击
                            if (item.children) {
                              if (item.children[0].children) {
                                if (item.value !== provinceCode) {
                                  setProvinceCode(item.value);
                                  setCityCode("");
                                  setAreaCode("");
                                }
                                setShowProvinceChildren(item.value);
                              } else {
                                if (item.value !== cityCode) {
                                  setProvinceCode("");
                                  setCityCode(item.value);
                                  setAreaCode("");
                                }
                                setShowCityChildren(item.value);
                              }
                            } else {
                              setProvinceCode(item.value);
                              setCityCode("");
                              setAreaCode("");
                            }
                          }}
                          className={
                            item.children
                              ? item.children[0].children
                                ? provinceCode === item.value
                                  ? "active"
                                  : ""
                                : cityCode === item.value
                                  ? "active"
                                  : ""
                              : provinceCode === item.value
                                ? "active"
                                : ""
                          }
                        >
                          {item.title}&nbsp;
                          {item.title !== "全国" && (
                            <DownOutlined
                              rotate={
                                // provinceCode === item.value ? 180 : 0
                                item.children[0].children
                                  ? showProvinceChildren === item.value
                                    ? 180
                                    : 0
                                  : showCityChildren === item.value
                                    ? 180
                                    : 0
                              }
                            />
                          )}
                        </span>

                        {item.children && (
                          <ul
                            className={[
                              "cityCode",
                              item.children[0].children
                                ? showProvinceChildren === item.value
                                  ? "active"
                                  : ""
                                : showCityChildren === item.value
                                  ? "active"
                                  : "",
                            ].join(" ")}
                            style={{ marginLeft: marginLeft }}
                          >
                            <li key={-1}>
                              {item.children[0]?.children ? (
                                <span
                                  className={
                                    provinceCode === item.value &&
                                      cityCode === ""
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    setCityCode("");
                                    setAreaCode("");
                                  }}
                                >
                                  全省
                                </span>
                              ) : (
                                <span
                                  className={
                                    cityCode === item.value && areaCode === ""
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    setAreaCode("");
                                  }}
                                >
                                  全市
                                </span>
                              )}
                            </li>
                            {item.children.map((childItem, childIndex) => {
                              return (
                                <li
                                  onClick={(e) => {
                                    if (childItem.children) {
                                      setCityCode(childItem.value);
                                    } else {
                                      setAreaCode(childItem.value);
                                    }
                                    setChildML(-e.currentTarget.offsetLeft);
                                  }}
                                  className={
                                    childItem.children
                                      ? cityCode === childItem.value
                                        ? "active"
                                        : "noStyle"
                                      : areaCode === childItem.value
                                        ? "active"
                                        : "noStyle"
                                  }
                                  key={childIndex}
                                >
                                  <span>
                                    {childItem.title}&nbsp;
                                    {childItem.children ? (
                                      <DownOutlined
                                        rotate={
                                          cityCode === childItem.value ? 180 : 0
                                        }
                                      />
                                    ) : null}
                                  </span>

                                  {childItem.children && (
                                    <ul
                                      className={[
                                        "areaCode",
                                        cityCode == childItem.value
                                          ? "active"
                                          : "",
                                      ].join(" ")}
                                      style={{ marginLeft: childML }}
                                    >
                                      {childItem.children.map(
                                        (grandSonItem, grandSonIndex) => {
                                          return (
                                            <li
                                              className={
                                                areaCode == grandSonItem.value
                                                  ? "active"
                                                  : ""
                                              }
                                              key={grandSonIndex}
                                              onClick={() =>
                                                setAreaCode(grandSonItem.value)
                                              }
                                            >
                                              {grandSonItem.title}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
              {queryToData().currentIndex === "2" || (
                <li className={"condition-part-types second_type"}>
                  <div className={"condition-part-types-name"}>
                    类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型:
                  </div>
                  {queryToData().currentIndex === "4" ? (
                    <ul className={"condition-part-types-item"}>
                      {second_class?.map((item, index) => {
                        return (
                          <li
                            onClick={() =>
                              setSecond_class_value(item.get("type"))
                            }
                            className={
                              second_class_value === item.get("type")
                                ? "active"
                                : ""
                            }
                            key={index}
                          >
                            {item.get("name")}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <ul className={"condition-part-types-item"}>
                      <li
                        onClick={() => setSecond_class_value("")}
                        className={second_class_value === "" ? "active" : ""}
                        key={0}
                      >
                        全部
                      </li>
                      <li
                        onClick={() => setSecond_class_value("residence")}
                        className={
                          second_class_value === "residence" ? "active" : ""
                        }
                        key={1}
                      >
                        住宅用房
                      </li>
                      <li
                        onClick={() => setSecond_class_value("commercial")}
                        className={
                          second_class_value === "commercial" ? "active" : ""
                        }
                        key={2}
                      >
                        商业用房
                      </li>
                    </ul>
                  )}
                </li>
              )}
              <li className={"condition-part-types price"}>
                <div className={"condition-part-types-name"}>
                  价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格:
                </div>
                <ul className={"condition-part-types-item"}>
                  {objectPriceSelect.map((item, index) => {
                    return (
                      <li
                        key={item.label}
                        onClick={() => {
                          setPrice(item.value);
                          getObjectList(1, item.value, zc_type);
                        }}
                      >
                        <span className={price === item.value ? "active" : ""}>
                          {" "}
                        </span>
                        {item.label}
                      </li>
                    );
                  })}
                </ul>
              </li>
              {queryToData().currentIndex !== "4" &&
                queryToData().currentIndex !== "2" && (
                  <li className={"condition-part-types auction_status"}>
                    <div className={"condition-part-types-name"}>拍卖状态:</div>
                    <ul className={"condition-part-types-item"}>
                      {auctionStatus.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              setObject_status(item.value);
                            }}
                            className={
                              object_status === item.value ? "active" : ""
                            }
                            key={item.value}
                          >
                            {item.label}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                )}
              {!queryToData().currentIndex && (
                <li className={"condition-part-types auction_type"}>
                  <div className={"condition-part-types-name"}>拍卖类型:</div>
                  <ul className={"condition-part-types-item"}>
                    {auctionType.map((item, index) => {
                      return (
                        <li
                          onClick={() => {
                            setZc_type(item.value);
                            getObjectList(1, price, item.value);
                          }}
                          className={zc_type === item.value ? "active" : ""}
                          key={item.label}
                        >
                          {item.label}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}
              {queryToData().currentIndex === "2" && (
                <li className={"condition-part-types auction_status"}>
                  <div className={"condition-part-types-name"}>处置状态:</div>
                  <ul className={"condition-part-types-item"}>
                    {failAuctionStatus.map((item, index) => {
                      return (
                        <li
                          onClick={() => {
                            setDisposal_status(item.value);
                          }}
                          className={
                            disposal_status === item.value ? "active" : ""
                          }
                          key={item.label}
                        >
                          {item.label}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}
            </ul>
            <div className={"resetting"}>
              <span onClick={Reset}>重置条件</span>
            </div>
          </div>
        </div>
        <div className={"result container-1200"}>
          <div className={"result-left"}>
            <div className={"sort"}>
              <ul>
                {sortList.map((item, index) => {
                  return (
                    <li
                      onClick={() => setOrder_type(item.value)}
                      className={order_type === item.value ? "active" : ""}
                      key={item.value}
                    >
                      {item.label}
                      {index === 0 && <SwapOutlined className={"change"} />}
                    </li>
                  );
                })}
              </ul>
            </div>
            {/*<div className={"total_count"}>*/}
            {/*  共找到<span>{count}</span>条标的物*/}
            {/*</div>*/}
            <div className={"results"}>
              <ul className={"results_content"}>
                {objectList.map((item, index) => {
                  return (
                    <li key={index} className={"results_item"}>
                      <span
                        className={
                          item.zc_type === 1
                            ? "tagName"
                            : item.zc_type === 2
                              ? "tagName blue"
                              : "tagName red"
                        }
                      >
                        {item.zctype_name}
                      </span>
                      <div
                        className={"cover_img"}
                        style={{ backgroundImage: `url(${item.img_path})` }}
                      />
                      {item.zc_type !== 3 ? (
                        <div className={"right_content"}>
                          <div className={"right_content_l"}>
                            <div className={"center_content_title"}>
                              {item.object_title}
                            </div>
                            <ul>
                              <li>
                                <div className={"des"}>标的物编号：</div>
                                <div className={"infos"}>{item.id}</div>
                              </li>
                              <li>
                                <div className={"des letter1"}>保证金：</div>
                                <div className={"infos"}>
                                  ¥{transPrice(item.security_deposit || 0)}
                                </div>
                              </li>
                              <li>
                                <div className={"des letter2"}>处置机构：</div>
                                <div className={"infos"}>
                                  {item.zc_type === 1 ? (
                                    <>{item.court_name}</>
                                  ) : (
                                    <>{item.merchants_name}</>
                                  )}
                                </div>
                              </li>
                              <li>
                                <div className={"des"}>加价幅度：</div>
                                <div className={"infos"}>
                                  ¥{transPrice(item.bid_increment || 0)}
                                </div>
                              </li>
                              <li>
                                <div className={"des letter2"}>辅助机构：</div>
                                <div className={"infos"}>{item.org_name}</div>
                              </li>
                              <li></li>
                              <li>
                                <div className={"des letter2"}>
                                  {queryToData().currentIndex === "2"
                                    ? "上架时间："
                                    : "开拍时间："}
                                </div>
                                <div className={"infos"}>
                                  {queryToData().currentIndex === "2"
                                    ? item.create_time
                                    : item.start_time}
                                </div>
                              </li>
                            </ul>
                            <img
                              className={"source_icon"}
                              src={item.sourceIcon}
                              alt=""
                            />
                          </div>
                          <div className={"right_content_r"}>
                            <div className={"start_price"}>
                              <span className={"des"}>评估价：</span>
                              <b>
                                ¥{transPrice(item.assess_price || 0, "zh_cn")}
                              </b>
                            </div>
                            <div className={"assets_price"}>
                              <span className={"des"}>
                                {queryToData().currentIndex === "2"
                                  ? "当前价："
                                  : "起拍价："}
                              </span>
                              <span className={"red"}>¥</span>
                              <b className={"red"}>
                                {transPrice(item.initial_price || 0, "zh_cn")}
                              </b>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={"right_content right_content_type3"}>
                          <div className={"right_content_l"}>
                            <div className={"center_content_title"}>
                              {item.object_title}
                            </div>
                            <ul>
                              <li>
                                <div className={"des"}>标的物编号：</div>
                                <div className={"infos"}>{item.id}</div>
                              </li>
                              <li>
                                <div className={"des"}>标的物类型：</div>
                                <div className={"infos"}>
                                  {item.second_class}
                                </div>
                              </li>
                              <li>
                                <div className={"des"}>围观&nbsp;</div>
                                <div className={"infos"}>
                                  {item.own_looker_num}
                                </div>
                                <span>&nbsp;|&nbsp;</span>
                                <div className={"des"}>咨询&nbsp;</div>
                                <div className={"infos"}>
                                  {item.consult_num}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className={"right_content_r"}>
                            <div className={"assets_price"}>
                              <span className={"des"}>评估价：</span>
                              <span className={"infos"}>
                                ¥{transPrice(item.assess_price || 0, "zh_cn")}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={"coverCode"}>
                        <div className={"coverCode-content"}>
                          <div>
                            <img src={wxCodeImg} alt="" />
                            <span>矩石阵微信小程序</span>
                          </div>
                          {/* <div>
                            <img src={codeSelect[0]} alt="" />
                            <span>ios下载</span>
                          </div>
                          <div>
                            <img src={codeSelect[1]} alt="" />
                            <span>Android下载</span>
                          </div> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {/*{count !== 0 && (*/}
              {/*  <Pagination*/}
              {/*    style={{ textAlign: "right", marginBottom: "30px" }}*/}
              {/*    current={page}*/}
              {/*    showSizeChanger={false}*/}
              {/*    total={count}*/}
              {/*    pageSize={size}*/}
              {/*    onChange={(e) => getObjectList(e)}*/}
              {/*    showQuickJumper*/}
              {/*    showTotal={(total) => `共 ${total} 条`}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
            {/*{count === 0 && <Empty />}*/}
            {objectList.length === 0 && <Empty style={{ margin: "120px 0" }} />}
            {(objectList.length > 0 || page > 1) && (
              <div
                className={"customize-btn-group textRight"}
                style={{ marginBottom: "30px" }}
              >
                <div
                  onClick={() => {
                    setPage(page - 1);
                    getObjectList(page - 1, price, zc_type);
                    window.scrollTo(0, 640);
                  }}
                  className={[
                    "ant-pagination-prev",
                    page === 1 ? "disable" : "",
                  ].join(" ")}
                >
                  <button className="ant-pagination-item-link">
                    <LeftOutlined />
                  </button>
                </div>
                <a className={"jumpPage"}>{page}</a>
                <div
                  className={[
                    "ant-pagination-prev",
                    objectList.length < size ? "disable" : "",
                  ].join(" ")}
                  onClick={() => {
                    console.log(Number(page) + 1);
                    setPage(Number(page) + 1);
                    getObjectList(Number(page) + 1, price, zc_type);
                    window.scrollTo(0, 640);
                  }}
                >
                  <button className="ant-pagination-item-link">
                    <RightOutlined />
                  </button>
                </div>
                <div className={"ant-pagination-prev"}>
                  <div className="ant-pagination-options-quick-jumper">
                    跳至
                    <Input
                      type="text"
                      onChange={(e) => {
                        setJumpPage(e.target.value);
                      }}
                      onPressEnter={(e) => {
                        if (e.target.value) {
                          setPage(e.target.value);
                          getObjectList(e.target.value, price, zc_type);
                          window.scrollTo(0, 640);
                          e.target.blur();//让input失去焦点
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value) {
                          setPage(e.target.value);
                          getObjectList(e.target.value, price, zc_type);
                          window.scrollTo(0, 640);
                        }
                      }}
                    />
                    页
                  </div>
                </div>
              </div>
            )}
          </div>
          <HotObject
            currentIndex={
              !queryToData().currentIndex
                ? ""
                : queryToData().currentIndex === "1"
                  ? 1
                  : queryToData().currentIndex > 2
                    ? queryToData().currentIndex - 1
                    : "-1"
            }
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
  second_class: state.getIn(["rootReducer", "second_class"]),
});
// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
